<template>
    <div>
        <template v-if="row.item.type != 'eingang'">
            <b-button
                v-if="!row.item.gf_document.url"
                variant="primary"
                class="ml-2"
                @click="$root.$children[0].openModal('document-prepare-modal', { incoming_invoice_id:row.item.id,type:'gutschrift'}, refreshTable)"
            >
                {{ $t('incoming_invoice.button.generate') }}
            </b-button>
            <span v-else>
                                <a target="_blank" @click.prevent="$root.openDocument(row.item.gf_document.name)"
                                   :href="$root.getDocumentUrl(row.item.gf_document.name)">{{
                                        row.item.gf_document.name
                                    }}</a>
                            </span>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        changeVerified(id) {
            this.$store.dispatch('IncomingInvoices/changeVerifiedIncomingInvoice', id).finally(() => {
                this.refreshTable()
            })
        },
    }
}
</script>